<template>
    <div class="bg-white p-5" style="margin: 30px; border-radius: 10px; min-height: calc(100vh - 300px)">
        <h1 class="text-primary d-flex align-items-center">
            <div class="d-inline-flex min-w-75px min-h-75px rounded-circle align-items-center mt-0 mr-2" style="background: rgba(188, 0, 3, 0.11);">
                <svg width="40px"
                     height="40px"
                     viewBox="0 0 15 15"
                     class="bi bi-tag d-block"
                     fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg"
                     style="margin: 0 auto;"
                >
                    <path fill-rule="evenodd" d="M2 2v4.586l7 7L13.586 9l-7-7H2zM1 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2z" />
                    <path fill-rule="evenodd" d="M4.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                </svg>
            </div>
            {{ addressSet.title }} <span style="color: rgba(188, 0, 3, 0.11);">#{{ addressSet.id }}</span>
        </h1>
        <b-tabs class="mt-5">
            <b-tab class="p-3">
                <template #title>
                    <svg width="1em"
                         height="1em"
                         viewBox="0 0 16 16"
                         class="bi bi-bookshelf"
                         fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path fill-rule="evenodd" d="M2.5 0a.5.5 0 0 1 .5.5V2h10V.5a.5.5 0 0 1 1 0v15a.5.5 0 0 1-1 0V15H3v.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 .5-.5zM3 14h10v-3H3v3zm0-4h10V7H3v3zm0-4h10V3H3v3z" />
                    </svg>
                    <span class="ml-3">Adressen</span>
                </template>
                <FirewallAddressSetAddresses v-if="addressSet" :address-set="addressSet" />
            </b-tab>
            <b-tab class="p-3">
                <template #title>
                    <svg width="1em"
                         height="1em"
                         viewBox="0 0 16 16"
                         class="bi bi-diagram-3"
                         fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z" />
                    </svg>
                    <span class="ml-3">Zugehörige</span>
                </template>
                <FirewallAddressSetRelations v-if="addressSet" :address-set="addressSet" />
            </b-tab>
            <b-tab class="p-3">
                <template #title>
                    <svg width="1em"
                         height="1em"
                         viewBox="0 0 16 16"
                         class="bi bi-command"
                         fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path fill-rule="evenodd" d="M3.5 2a1.5 1.5 0 1 0 0 3H5V3.5A1.5 1.5 0 0 0 3.5 2zM6 5V3.5A2.5 2.5 0 1 0 3.5 6H5v4H3.5A2.5 2.5 0 1 0 6 12.5V11h4v1.5a2.5 2.5 0 1 0 2.5-2.5H11V6h1.5A2.5 2.5 0 1 0 10 3.5V5H6zm4 1H6v4h4V6zm1-1h1.5A1.5 1.5 0 1 0 11 3.5V5zm0 6v1.5a1.5 1.5 0 1 0 1.5-1.5H11zm-6 0H3.5A1.5 1.5 0 1 0 5 12.5V11z" />
                    </svg>
                    <span class="ml-3">Aktionen</span>
                </template>
                <FirewallAddressSetActions v-if="addressSet" :address-set="addressSet" />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { FirewallAddressSet } from '@/core/services/store/models/firewall_address_set'
import FirewallAddressSetAddresses from '@/view/pages/firewall_address_sets/FirewallAddressSetAddresses'
import FirewallAddressSetRelations from '@/view/pages/firewall_address_sets/FirewallAddressSetRelations'
import FirewallAddressSetActions from '@/view/pages/firewall_address_sets/FirewallAddressSetActions'

export default {
    name: 'FirewallAddressSetSingle',
    components: { FirewallAddressSetActions, FirewallAddressSetRelations, FirewallAddressSetAddresses },
    computed: {
        addressSet() {
            const addressSet = FirewallAddressSet.find(this.$route.params.id)
            if (!addressSet) { FirewallAddressSet.fetchSingle(this.$route.params.id) }
            return addressSet
        }
    }
}
</script>

<style scoped>

</style>
