<template>
    <div>
        <table class="table">
            <thead>
                <tr>
                    <th>
                        <b-input v-model="newAddress.title" placeholder="Titel" size="sm" />
                    </th>
                    <th>
                        <b-input v-model="newAddress.cidr" placeholder="IP/Netz" size="sm" />
                    </th>
                    <th style="width: 1%">
                        <b-button block class="float-right" size="sm" variant="outline-primary" @click="onSubmit">
                            <svg width="1em"
                                 height="1em"
                                 viewBox="0 0 16 16"
                                 class="bi bi-plus"
                                 fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg"
                            >
                                <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                        </b-button>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="address in addresses" :key="address.id">
                    <td class="pl-6">
                        {{ address.title }}
                    </td>
                    <td class="pl-6">
                        {{ address.cidr }}
                    </td>
                    <td style="width: 1%">
                        <b-button block class="float-right" size="sm" variant="outline-primary" @click="onDelete(address)">
                            <svg width="1em"
                                 height="1em"
                                 viewBox="0 0 16 16"
                                 class="bi bi-trash"
                                 fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                            </svg>
                        </b-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'FirewallAddressSetAddresses',
    props: {
        addressSet: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            fields: [
                'title',
                'cidr',
                'action'
            ],
            addresses: [],
            newAddress: {
                title: '',
                cidr: ''
            }
        }
    },
    methods: {
        async fetchAddresses() {
            this.addresses = []
            const response = await this.api.getFirewallAddressSetAddresses(this.addressSet.id)
            this.addresses = response.data
        },
        async onSubmit() {
            const response = await this.api.createFirewallAddressSetAddress(this.addressSet.id, this.newAddress)
            this.addresses.push(response.data)
            this.newAddress.title = ''
            this.newAddress.cidr = ''
        },
        async onDelete(address) {
            await this.api.deleteFirewallAddressSetAddress(this.addressSet.id, address.cidr)
            this.addresses = this.addresses.filter(item => item.cidr !== address.cidr)
        }
    },
    watch: {
        addressSet: function(val) {
            this.fetchAddresses()
        }
    },
    created() {
        this.fetchAddresses()
    }
}
</script>

<style scoped>

</style>
