<template>
    <div />
</template>

<script>
export default {
    name: 'FirewallAddressSetRelations',
    props: {
        addressSet: {
            type: Object,
            default: () => {}
        }
    }
}
</script>

<style scoped>

</style>
