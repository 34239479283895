<template>
    <b-button variant="primary" class="float-right" @click="onDelete">
        Löschen
    </b-button>
</template>

<script>
import projectMixin from '@/core/mixins/projectMixin'
import { FirewallAddressSet } from '@/core/services/store/models/firewall_address_set'

export default {
    name: 'FirewallAddressSetActions',
    mixins: [projectMixin],
    props: {
        addressSet: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        async onDelete() {
            const id = this.addressSet.id
            await this.api.deleteFirewallAddressSet(id)
            await this.$router.push({ name: 'projects-single', params: { uuid: this.project.uuid } })
            await FirewallAddressSet.delete(id)
        }
    }
}
</script>

<style scoped>

</style>
